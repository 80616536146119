<template>
  <b-card no-body class="rounded-3 shadow-lg">
    <!-- <b-row>
      <b-col>
      </b-col>
    </b-row> -->
    <!-- <b-card-body class="d-flex justify-content-between align-items-center pb-0"> -->
    <b-card-body
      class="d-flex justify-content-between align-items-center pb-0 flex-column flex-md-row"
    >
      <h2 class="font-weight-bolder">
        {{ statistic.in_out }} | {{ statistic.in_only }}
        <br />

        <small>( IN Area | IN Total )</small>
      </h2>
      <span class="text-center">
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span>
              <b-badge variant="primary"><strong>IN Area :</strong></b-badge>
              SAL
            </span>
            ( Realtime location )
            <span class="text-center"
              >Tidak terikat Filter
              <b-badge variant="danger" class="badge-glow button">
                By Today
              </b-badge>
            </span>
          </div>
        </b-alert>
      </span>
      <span class="text-center">
        <span>
          <b-badge variant="light-primary">
            <b-badge variant="primary"><strong>IN Total :</strong></b-badge>
            Sudah masuk area SAL dan Keluar
          </b-badge>
        </span>
        <br />( Realtime Filter
        <b-badge variant="light-primary">
          <b-badge variant="danger" class="badge-glow button">
            By Today
          </b-badge>
          /
          <b-badge variant="primary"> {{ todaySal }} ) </b-badge>
        </b-badge>
        <br />
        <span class="text-center"
          >Base on
          <b-badge variant="light-primary"> Clock IN Time </b-badge>
        </span>
      </span>
    </b-card-body>

    <vue-apex-charts
      type="line"
      height="50"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />
  </b-card>
</template>

<script>
import { BBadge, BAlert, BCard, BCardBody, BAvatar } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { lineChartOptions } from './chartOptions'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BBadge,
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    statistic: {
      type: [Object, Array],
      required: true
    },
    statisticTitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    chartData: {
      type: Array,
      default: () => []
    },
    chartOptions: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      todaySal: moment().format('DD MMM yyyy')
    }
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(lineChartOptions))

        options.fill.gradient.gradientToColors = [
          this.gradientToColor(this.color)
        ]
        options.colors = [$themeColors[this.color]]

        return options
      }
      return this.chartOptions
    }
  },
  methods: {
    moment,
    gradientToColor(color) {
      const gradientToColors = {
        primary: '#A9A2F6',
        success: '#55DD92',
        warning: '#ffc085',
        danger: '#F97794',
        info: '#59E0F0',
        secondary: '#B4B9BF',
        light: '#D0D4DB',
        dark: '#919191'
      }

      return gradientToColors[color]
    }
  }
}
</script>
